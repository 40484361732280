<template>
	<div id="no-found">
		<v-head></v-head>
        <div class="content">
			<div class="center">
				<svg t="1604038588480" class="icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg" p-id="2892" width="64" height="64"><path d="M511.957333 0a512 512 0 1 0 512 512 512 512 0 0 0-512-512z m39.978667 752.170667h-88.448v-106.965334h88.490667v106.922667z m41.130667-235.136a102.784 102.784 0 0 0-41.130667 81.322666v6.698667h-88.448v-11.776a154.581333 154.581333 0 0 1 67.584-122.496 96 96 0 0 0 40.021333-76.8 52.181333 52.181333 0 0 0-56.576-55.466667c-42.666667 0-59.434667 28.202667-64.256 66.090667h-83.626666a137.216 137.216 0 0 1 149.546666-132.864c108.074667 0.469333 141.141333 71.893333 141.141334 124.672a149.333333 149.333333 0 0 1-64.256 120.789333z" fill="#FFA002" p-id="2893"></path></svg>
				<div class="col">
					<div class="head">出錯了！</div>
					<div class="desc">您訪問的頁面不存在，請檢查網址<br>是否正確。</div>
					<router-link to="/" class="operate">返回首頁</router-link>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import vHead from '@/layouts/MobileHead.vue'
	export default {
		components: {
			vHead,
		}
	};
</script>

<style lang="scss" scoped>
	#no-found {
		display: flex;
		flex-direction: column;
		height: 100vh;
		width: 100%;
	}

	#no-found .content {
		flex: 1;
		background-color: #e8e8e8;
		display: flex;
		align-items: flex-start;
		justify-content: center;
	}

	#no-found .content .center {
		margin-top: 200px;
		width: 80%;
		height: 250px;
		background-color: #fff;
		border: 1px solid #e7e7e7;
		border-radius: 4px;
		display: flex;
		flex-direction: row;
		padding: 24px;
	}

	#no-found .content .center > .col {
		display: flex;
		flex-direction: column;
		font-size: 26px;
		font-weight: 400;
		color: #212121;
		margin-left: 24px;
	}

	#no-found .content .center > .col > .head {
		color: #f00;
		font-size: 34px;
		font-weight: bold;
		line-height: 40px;
		margin-bottom: 25px;
	}

	#no-found .content .center > .col > .desc {
		font-weight: 400;
		font-size: 28px;
		color: rgb(255, 160, 2);
		margin-bottom: 40px;
	}

	#no-found .content .center > .col > .operate {
		text-decoration: underline;
		line-height: 30px;
		font-size: 28px;
		color: #36C4D0;
	}
</style>
